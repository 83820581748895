<template>
    <div v-loading="loading">
        <div>
            <el-page-header @back="goBack" content="项目编辑">
            </el-page-header>
        </div>

        <div style="padding-top: 50px">

            <div style="display: flex;justify-content: space-between;width: 70%">
                <div style="width: 25%;font-family: 新宋体;">*项目名称</div>
                <div style="width: 25%;font-family: 新宋体;">*项目分类</div>
<!--                <div style="width: 25%;font-family: 新宋体;">*项目状态</div>-->
                <div style="width: 25%;font-family: 新宋体;">*最大投资次数</div>
            </div>

            <div style="display: flex;justify-content: space-between;width: 70%;padding-top: 5px">
                <div style="width: 25%">
                    <el-input style="width: 200px" clearable v-model="project.projectTitle"
                              placeholder="请输入项目名称"></el-input>
                </div>
                <div style="width: 25%">
                    <el-select v-model="project.projectTypeId" clearable placeholder="请选择项目分类">
                        <el-option
                                v-for="item in typeList"
                                :key="item.id"
                                :label="item.typeName"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </div>
<!--                <div style="width: 25%">-->
<!--                    <el-select v-model="project.projectStatus" clearable placeholder="请选择项目状态">-->
<!--                        <el-option-->
<!--                                v-for="item in option"-->
<!--                                :key="item.value"-->
<!--                                :label="item.name"-->
<!--                                :value="item.value">-->
<!--                        </el-option>-->
<!--                    </el-select>-->
<!--                </div>-->
                <div style="width: 25%">
                    <el-input v-model="project.maxNum" oninput="value=value.replace(/^(0+)|[^\d]+/g,'')"
                              style="width: 200px" clearable
                              placeholder="请输入最大投资次数"></el-input>
                </div>
            </div>

            <div style="display: flex;justify-content: space-between;width: 70%;padding-top: 20px">
                <div style="width: 25%;font-family: 新宋体;">*项目规模</div>
                <div style="width: 25%;font-family: 新宋体;">
                    *收益率（%）
                </div>
                <div style="width: 25%;font-family: 新宋体;">
                    *模拟进度
                </div>
                <div style="width: 25%;font-family: 新宋体;">
                    *自增进度（30分钟）（%）
                </div>
            </div>

            <div style="display: flex;justify-content: space-between;width: 70%;padding-top: 5px">
                <div style="width: 25%">
                    <el-input oninput="value=value.replace(/^(0+)|[^\d]+/g,'')" style="width: 200px" clearable
                              v-model="project.projectSize"
                              placeholder="请输入项目规模"></el-input>
                </div>
                <div style="width: 25%">
                    <el-input oninput="value=value.replace(/[^\d.]/g,'')" style="width: 200px" clearable
                              v-model="project.projectRate"
                              placeholder="请输入项目收益率"></el-input>
                </div>
                <div style="width: 25%">
                    <el-input oninput="value=value.replace(/[^\d.]/g,'')" style="width: 200px" clearable
                              v-model="project.projectProgress"
                              placeholder="请输入模拟进度"></el-input>
                </div>
                <div style="width: 25%">
                    <el-input oninput="value=value.replace(/[^\d.]/g,'')" style="width: 200px" clearable
                              v-model="project.autoProgress"
                              placeholder="请输入自增进度"></el-input>
                </div>
            </div>

            <div style="display: flex;justify-content: space-between;width: 70%;padding-top: 20px">
                <div style="width: 25%;font-family: 新宋体;">
                    *周期（天）
                </div>
                <div style="width: 25%;font-family: 新宋体;">
                    *结算模式
                </div>
                <div style="width: 25%;font-family: 新宋体;">
                    *最小投资金额
                </div>
                <div style="width: 25%;font-family: 新宋体;">
                    *最大投资金额
                </div>
            </div>

            <div style="display: flex;justify-content: space-between;width: 70%;padding-top: 5px">
                <div style="width: 25%">
                    <el-input oninput="value=value.replace(/^(0+)|[^\d]+/g,'')" style="width: 200px" clearable
                              v-model="project.projectCycle"
                              placeholder="请输入项目周期"></el-input>
                </div>
                <div style="width: 25%">
                    <el-select v-model="project.refundId" clearable placeholder="请选择结算模式">
                        <el-option
                                v-for="item in refundType"
                                :key="item.id"
                                :label="item.refundType"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </div>
                <div style="width: 25%">
                    <el-input oninput="value=value.replace(/^(0+)|[^\d]+/g,'')" style="width: 200px" clearable
                              v-model="project.investmentMinNum"
                              placeholder="请输入最小投资金额"></el-input>
                </div>
                <div style="width: 25%">
                    <el-input oninput="value=value.replace(/^(0+)|[^\d]+/g,'')" style="width: 200px" clearable
                              v-model="project.investmentMaxNum"
                              placeholder="请输入最大投资金额"></el-input>
                </div>
            </div>

            <div style="display: flex;justify-content: space-between;width: 70%;padding-top: 30px;font-family: 新宋体;">
                <div>
                    *项目描述
                </div>
            </div>

            <div style="display: flex;justify-content: space-between;width: 70%;padding-top: 20px">
                <div>
                    <el-input clearable v-model="project.projectDetail" style="width: 600px" maxlength="300"
                              show-word-limit placeholder="请输入项目描述" rows="7"
                              type="textarea"></el-input>
                </div>
            </div>

            <div style="display: flex;justify-content: space-between;width: 70%;padding-top: 30px;font-family: 新宋体;">
                <div>
                    *项目封面
                </div>
            </div>

            <div style="display: flex;justify-content: space-between;width: 70%;padding-top: 30px">
                <div>
                    <el-upload
                            class="avatar-uploader"
                            :action="api+'/upload/uploadPic'"
                            :show-file-list="false"
                            :on-success="handleAvatarSuccess"
                            :before-upload="beforeAvatarUpload">
                        <img v-if="imageUrl" :src="imageUrl" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </div>
            </div>

        </div>

        <div style="padding-top: 30px;display: flex;justify-content: center">
            <el-button size="medium" @click="goBack">取消</el-button>
            <el-button type="primary" size="medium" style="margin-left: 70px" @click="submit">提交</el-button>
        </div>
    </div>
</template>

<script>
    import Global from "../../utils/global";

    export default {
        name: "ProjectEdit",
        data() {
            return {
                loading: false,
                typeList: [],//项目分类
                option: [
                    {
                        value: 1,
                        name: '上架'
                    },
                    {
                        value: 0,
                        name: '不上架'
                    },
                ],
                refundType: [],
                api: null,
                project: {},
                imageUrl: ''
            }
        },
        methods: {
            submit() {
                this.loading = true
                this.project.imageUrl = this.imageUrl;
                this.axios.post('/project/projectEdit', this.project).then(resp => {
                    if (resp.data.code == 200) {
                        this.$message.success(resp.data.message)
                        setTimeout(() => {
                            this.goBack()
                        }, 700)
                    }
                }).finally(() => {
                    this.loading = false
                })
            },
            handleAvatarSuccess(res, file) {
                if (res.code == 200) {
                    this.$message.success('上传成功')
                    this.imageUrl = res.data;
                } else {
                    this.$message.warning(res.message)
                }
            },
            beforeAvatarUpload(file) {
                const isJPG = file.type === 'image/jpeg';
                const isLt2M = file.size / 1024 / 1024 < 5;

                if (!isJPG) {
                    this.$message.error('上传头像图片只能是 JPG 格式!');
                }
                if (!isLt2M) {
                    this.$message.error('上传头像图片大小不能超过 5MB!');
                }
                return isJPG && isLt2M;
            },
            findRefundType() {
                this.loading = true;
                this.axios.get('/project/findRefundType').then(resp => {
                    if (resp.data.code == 200) {
                        this.refundType = resp.data.data;
                    }
                }).finally(() => {
                    this.loading = false
                })
            },
            goBack() {
                this.$router.replace('/projectList')
            },
            findProjectType() {
                this.loading = true;
                this.axios.get('/project/findTypeAll').then(resp => {
                    if (resp.data.code == 200) {
                        this.typeList = resp.data.data;
                    }
                }).finally(() => {
                    this.loading = false
                })
            },
            findById(id){
                this.loading=true
                this.axios.get('/project/findById?id='+id).then(resp=>{
                    if (resp.data.code==200){
                        this.project=resp.data.data
                        this.imageUrl=this.project.imageUrl
                    }
                }).finally(()=>{
                    this.loading=false
                })
            }
        },
        created() {
            this.findProjectType()
            this.findRefundType()
            this.api = Global.host
            this.project.id = this.$route.query.id
            this.findById(this.project.id)

        }
    }
</script>

<style scoped>
    .el-select {
        width: 200px;
    }

    .el-input {
        width: 300px;
    }

    .avatar-uploader {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 300px;
        height: 300px;
        line-height: 300px;
        text-align: center;
    }

    .avatar {
        width: 300px;
        height: 300px;
        display: block;
    }
</style>
